<template>
  <div>
    <div class="card no-border">
      <a v-bind:href="download" class="w-inline-block">
        <h6 v-if="showTitleC=='true'">{{ post.post_title }}</h6>
        <img v-if="!this.post.animated" v-bind:src="this.compressedPhoto" @error="imageLoadError" alt class="card-image-head" />
       <video v-else v-bind:src="this.post.media" alt class="card-video-head" autoplay muted loop playsinline></video>
      </a>
      <div class="card-body">
        <div class="post-description-container">
          <textarea
            v-model="descriptionFilterBranding"
            ref="postDescription"
            class="form-input text-area-input w-input post-description"
          ></textarea>
          <span class="copy-text" v-on:click="copy"
            ><i class="fas fa-copy"></i
          ></span>
        </div>
        <a
          v-bind:href="download"
          class="button w-inline-block post-download"
          download
          target="_blank"
          v-on:click="track('download')"
        >
          <div>
            Download
            <i class="fas fa-download"></i>
          </div>
        </a>
        <a
          v-bind:href="post.editable_link"
          class="button w-inline-block post-edit"
          download
          target="_blank"
          v-on:click="track('edit')"
          v-if="post.editable_link !== null && post.editable_link !== ''"
        >
          <div>
            Edit
            <i class="fas fa-edit"></i>
          </div>
        </a>
        <ShareButton :post="post" />
                <a
          v-bind:href="butterLink"
          class="button w-inline-block post-find"
          target="_blank"
          v-if="superAdmin == true "
        >
          <div>
            Find
            <i class="fas fa-hand-point-left"></i>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ShareButton from "@/components/ScheduleButton.vue";
export default {
  props: {
    post: Object,
    showTitle: Boolean,
  },
  components: {
    ShareButton,
  },
  computed: {
    day: function () {
      return "hi";
    },
    showTitleC: function () {
      if (this.showTitle == null || this.showTitle == "") {
        return false;
      }
      return this.showTitle;
    },
    download: function () {
      var media = this.post.media;
      media = media.substring(26);
      var extension = ".png";
      if(this.post.animated)
      {
        extension = ".mp4";
      }
      var safeTitle = encodeURI(this.post.post_title + extension);
      var url =
        "https://fs.buttercms.com/content=t:attachment,f:%22" +
        safeTitle +
        "%22/" +
        media;
      return url;
    },
    superAdmin: function () {
      return this.$auth.user["https://theagentnest.com/super_admin"];
    },
    butterLink : function(){
      return "https://buttercms.com/content/workspace/12628/list/social_media_posts/entry/"+this.post.meta.id;
    },
    compressedPhoto: function () {
       if(this.post.animated)
          return this.post.media;
      return this.resizePhoto(this.post.media, 400);
    },
    descriptionFilterBranding() {
      //filters out agent nest branding for white label
      if (
        this.post.description != null &&
        process.env.VUE_APP_WHITELABEL == "true"
      ) {
        var post = this.post.description.replaceAll("#theagentnest", "");
        return post.replaceAll("#agentnest", "");
      }
      return this.post.description;
    },
  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYYMMDD");
      }
    },
    copy() {
      this.copyText(this.$refs.postDescription); //Custom mixin. Ref is defined in the element e.g. <div ref="text">
      this.$swal.fire({
        title: "Text Copied to Clipboard",
        timer: 1200,
        icon: "success",
        toast: true,
      });
    },
    imageLoadError() {
      event.target.src = this.post.media;
    },
    track(edit) {
      var title = "Downloaded Post";
      if (edit == "edit") {
        title = "Edited Post";
      }
      // eslint-disable-next-line no-undef
      mixpanel.track(title, {
        Title: this.post_title,
        Calendar: true,
        Category: this.post.type,
      });
    },
  },
};
</script>

<style  scoped>
.post-edit {
  margin-left: 15px;
  padding: 5px 10px;
  cursor: pointer;
}
.post-description {
  resize: none;
  width: 110%;
  margin-left: -15px;
}
.copy-text {
  margin-top: 5px;
  float: right;
  cursor: pointer;
}
.post-description-container {
  margin-bottom: 15px;
}
.post-find {
  margin-top: -40px;
  margin-right: 20px;
  padding: 5px 10px;
  cursor: pointer;
  float:right;
}
.card-video-head{
  width:280px;
}
</style>