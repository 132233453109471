<template>
  <div>
    <div class="section bg-gray-4">
      <div class="container">
        <div class="w-layout-grid grid-quarters cards-grid">
          <SocialMediaPost
            v-for="post in posts"
            :key="post.post_title"
            :post="post"
            :showTitle="ShowTitle"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CalendarService from "@/services/calendarService.js";
import SocialMediaPost from "@/components/SocialMediaPost.vue";
export default {
  data() {
    return {
      posts: [],
    };
  },
  props: {
    Category: String,
    ShowTitle: Boolean,
  },
  components: {
    SocialMediaPost,
  },
  created() {
    CalendarService.getPosts(this.Category)
      .then((response) => {
        this.posts = response.data.data.social_media_posts.reverse();
        CalendarService.getSecondaryPosts(this.Category)
          .then((response) => {
            this.posts = this.posts.concat(
              response.data.data.social_media_posts.reverse()
            );
          })
          .catch((error) => {
            console.log("There was an error getting the calendar", error);
          });
      })
      .catch((error) => {
        console.log("There was an error getting the calendar", error);
      });
  },
};
</script>

<style scoped>
</style>